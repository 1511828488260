<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
        >
          <!-- <label>{{ $t('AppDigitalRecord.entries') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          /> -->
          <b-form
            inline
            @submit.stop.prevent
          >
            <label
              class="sr-only"
            >
              {{ $t('AppDigitalRecord.sysExpertKey') }}
            </label>
            <b-form-input
              v-model="newNomenclatureClave"
              class="mb-2 mr-sm-1 mb-sm-1 col-md-6 col-sm-3"
              :placeholder=" $t('AppDigitalRecord.sysExpertKey') "
            />
            <b-button
              variant="success"
              class="ml-7 mb-2 mb-sm-1"
              @click="newCustomerNomenclature"
            >
              <feather-icon
                icon="PenToolIcon"
              />
              {{ $t('AppDigitalRecord.newNomenclature') }}
            </b-button>
          </b-form>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block "
              :placeholder="$t('AppDigitalRecord.placeHolderSearch')"
              type="number"
              v-on:keypress="isInteger"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refListTable"
      :items="fetchRecords"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('Lists.NoMatchingRecordsFound')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: Row Details -->
      <template #cell(show_files)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="() => {
            refetchFilesNomenclatureData(row)
          }"
        >
          <span class="vs-label">{{ row.detailsShowing ? 'Ocultar' : $t('AppDigitalRecord.showFilesRecords') }}</span>
        </b-form-checkbox>
      </template>
      <template #row-details="data">
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              icon="InfoIcon"
              class="mr-50 mt-25"
            />
            {{ $t('AppDigitalRecord.clickingMetadata') }}
          </div>
        </b-alert>
        <b-card>
          <b-form-group :label="$t('AppDigitalRecord.rootPathNomenclature')">
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown variant="outline-primary">
                  <template #button-content>
                    <feather-icon icon="Edit2Icon" />
                  </template>
                  <vue-perfect-scrollbar
                    v-once
                    :settings="perfectScrollbarSettings"
                    class="scrollable-container media-list scroll-area"
                    tagname="li"
                  >
                    <b-dropdown-item
                      v-for="itemMetadata in metadata"
                      :key="itemMetadata"
                      @click="() => {
                        doCopy(itemMetadata)
                      }"
                    >
                      {{ itemMetadata }}
                    </b-dropdown-item>
                  </vue-perfect-scrollbar>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input
                v-model="data.item.nomenclaturaRutaRaizCompletado"
              />
            </b-input-group>
          </b-form-group>
          <hr>
          <b-form-group
            v-for="item in data.item.filesNomenclatures"
            :key="item.idExpedienteDigitalTipoArchivo"
            :label="item.nombreExpedienteDigitalTipoArchivo"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown variant="outline-primary">
                  <template #button-content>
                    <feather-icon icon="Edit2Icon" />
                  </template>
                  <b-dropdown-item
                    v-for="itemMetadata in metadata"
                    :key="itemMetadata"
                    @click="() => {
                      doCopy(itemMetadata)
                    }"
                  >
                    {{ itemMetadata }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>

              <b-form-input
                v-model="item.nomenclaturaNombreFinalizado"
                :placeholder="$t('AppDigitalRecord.placeHolderFileNomenclature')"
              />
            </b-input-group>
          </b-form-group>
          <b-button
            variant="info"
            @click="saveChanges(data.item)"
          >
            <feather-icon
              icon="SaveIcon"
            />
            {{ $t('AppDigitalRecord.saveChanges') }}
          </b-button>
        </b-card>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.from }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRecords"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BInputGroupPrepend,
  BForm,
  BPagination,
  BFormCheckbox,
  BFormGroup,
  BDropdown,
  BInputGroup,
  BDropdownItem,
  BAlert,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  ref, computed, watch,
} from '@vue/composition-api'
import digitalRecordService from '@/services/digitalrecord.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import { isInteger } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormCheckbox,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BForm,
    BAlert,
    // vSelect,
    VuePerfectScrollbar,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  setup() {
    const {
      fetchNomenclatureRoots,
      fetchNomenclaturesFiles,
      fetchMetadata,
      updateNomenclatureRoot,
      updateNomenclaturesFiles,
      createNomenclatureRoot,
    } = digitalRecordService()
    const tableColumns = [
      { key: 'claveClienteSysExpert', label: i18n.t('AppDigitalRecord.sysExpertKey') },
      { key: 'nomenclaturaRutaRaizCompletado', label: i18n.t('AppDigitalRecord.rootPathNomenclature') },
      { key: 'show_files', label: i18n.t('AppDigitalRecord.showFilesRecords') },
    ]

    const refListTable = ref(null)
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const selectedCustomerSettings = ref(null)
    const metadata = ref([])
    const newNomenclatureClave = ref('')
    const dataMeta = computed(() => {
      const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRecords.value,
      }
    })
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const refetchData = () => {
      refListTable.value.refresh()
    }
    const refetchFilesNomenclatureData = row => {
      fetchNomenclaturesFiles(row.item.claveClienteSysExpert, data => {
        const { item } = row
        item.filesNomenclatures = data
        row.toggleDetails()
      })
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })

    const fetchRecords = (ctx, callback) => {
      fetchNomenclatureRoots({ claveClienteSysExpert: searchQuery.value }, data => {
        callback(data)
      })
    }

    fetchMetadata({}, data => {
      metadata.value = data
    })

    const saveChanges = data => {
      updateNomenclatureRoot(data)
      updateNomenclaturesFiles(data.claveClienteSysExpert, data.filesNomenclatures)
    }

    const newCustomerNomenclature = () => {
      createNomenclatureRoot({
        claveClienteSysExpert: newNomenclatureClave.value,
      }, () => {
        refetchData()
        newNomenclatureClave.value = ''
      })
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perfectScrollbarSettings,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      fetchRecords,
      selectedCustomerSettings,
      refetchFilesNomenclatureData,
      metadata,
      saveChanges,
      newNomenclatureClave,
      newCustomerNomenclature,
      // Validations
      isInteger,
    }
  },
  methods: {
    doCopy(value) {
      this.$copyText(value).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('AppDigitalRecord.copiedMetadata'),
            icon: 'BellIcon',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('AppDigitalRecord.cannotCopyMetadata'),
            icon: 'BellIcon',
          },
        })
      })
    },
  },
}
</script>
<style lang="sss" scoped>
</style>
